import React from 'react'
import Styled from 'styled-components';

const Section = Styled.div`

@media all {
	*,
	:after,
	:before {
		box-sizing: border-box;
	}

	.grid__item {
		float: left;
		padding-left: 22px;
		width: 100%;
		min-height: 1px;
	}

	@media only screen and (max-width:768px) {
		.grid__item {
			padding-left: 17px;
		}
	}

	.one-whole {
		width: 100%;
	}

	html:not(.tab-outline) :focus {
		outline: none;
	}

	p {
		margin: 0 0 15px 0;
	}

	strong {
		font-weight: 700;
	}

	small {
		font-size: 0.85em;
	}

	h3,
	h5 {
		display: block;
		margin: 0 0 7.5px;
	}

	@media only screen and (min-width:769px) {
		h3,
		h5 {
			margin: 0 0 15px;
		}
	}

	h5 a {
		text-decoration: none;
		font-weight: inherit;
	}

	h3 {
		font-weight: 700;
		line-height: 1.2;
	}

	h3 {
		font-size: 20px;
	}

	@media only screen and (min-width:769px) {
		h3 {
			font-size: 22px;
		}
	}

	h5 {
		text-transform: uppercase;
		letter-spacing: 0.2em;
		font-size: 0.75em;
		margin-bottom: 10px;
	}

	@media only screen and (max-width:768px) {
		h5 {
			margin-bottom: 5px;
		}
	}

	.text-spacing {
		margin-bottom: 15px;
        text-align: left; 
        font-weight: 600; 
        font-size: smaller;
	}

	ul {
		margin: 0 0 15px 30px;
		padding: 0;
		text-rendering: optimizeLegibility;
	}

	li {
		margin-bottom: 0.25em;
	}

	a {
		color: #002500;
		text-decoration: none;
		background: transparent;
	}

	a:hover {
		color: #002500;
	}

	small {
		display: block;
	}

	.rte:after {
		content: "";
		display: table;
		clear: both;
	}

	.rte {
		margin-bottom: 7.5px;
	}

	@media only screen and (min-width:769px) {
		.rte {
			margin-bottom: 15px;
		}
	}

	.section-header__title {
		margin-bottom: 0;
        text-align: center; 
        color: #fd6e0d;
	}

	.slide-cart-full .healthy-warning {
		padding-top: 10px;
		margin: 0px;
	}

	.slide-cart-full h3.section-header__title {
		font-size: 22px;
	}

	.slide-cart-full ul.ul2 {
		margin-bottom: 10px;
	}

	.slide-cart-full ul.ul2 li {
		margin: 0px;
	}

    .healthy-warning {
		background-color: #FEFCF6;
		padding: 30px 20px;
		margin-top: 10px;
		border: 1px solid #ffede0;
	}
	.ul2 {
		font-size: smaller;
		list-style: disc;
	}

    .healthy-warning {
		background-color: #FEFCF6;
		padding: 30px 20px;
		margin-top: 10px;
		border: 1px solid #ffede0;
	}
	.ul2 {
		font-size: smaller;
	}
    .row{
        background-color: #ffffff; 
        color: #002500; 
        padding: 10px 10px 0px;
    }
}
`;


export default function TestimonialDetail() {
    return (
        <Section>
            <div className="grid__item one-whole healthy-warning">
                <h3 className="section-header__title">Healthy Start Commitment</h3>
                <p className="rte text-spacing">To ensure the health and safety of every client, please agree to this Healthy Start Commitment. The practice of fasting involves calorie restriction. ProLon is a low calorie diet that mimics the effect of fasting. As with any reduced-calorie diet, do not start a fast if: </p>
                <p style={{textAlign: 'left'}}> </p>
                <ul className="ul2">
                    <li>During this period of COVID-19, if you are over 65 years of age, or are in a high-risk category.</li>
                    <li>If you have a diagnosis of or are on a prescription for diabetes (type 1 or type 2), cancer, or congestive heart failure, or you have a history of fainting, fast only with the approval and supervision of a healthcare practitioner.</li>
                    <li>If you have a fever, cough, diarrhea, signs of an active infection, or are at risk for a repetitive infection.</li>
                    <li>You are underweight, pregnant, or breastfeeding.</li>
                    <li>If you have a known, diagnosed, or suspected eating disorder.</li>
                    <li>You are under 18 years of age over the age of 70.</li>
                    <li>You have dietary restrictions</li>
                </ul> <strong>Do not use ProLon if:</strong>
                <ul>
                    <li>You are allergic to nuts, oats, sesame, celery/celeriac, or other ingredients in the product.</li>
                </ul>
                <div className="row">
                    <h5><small>*If you are not clear about the above, talk to your health care practitioner or <a href="/pages/thank-you-for-your-interest-in-prolon" target="_blank" title="Speak to a representative" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>click here</a> to talk to ProLon practitioner.<p></p></small>
            </h5></div>
            </div>
        </Section>
    )
}
